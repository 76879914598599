import React from 'react'
import { IconPOA } from '../IconPOA'
import { IconTelegram } from '../IconTelegram'
import { IconTwitter } from '../IconTwitter'

const getIconBackgroundColor = networkBranch => {
  return (
    {
      dai: '#e3e7e9',
      poa: '#fff',
      sokol: '#fff',
      kovan: '#fff'
    }[networkBranch] || '#fff'
  )
}

const getIconColor = networkBranch => {
  return (
    {
      dai: '#333',
      poa: '#5c34a2',
      sokol: '#6ac9b9',
      kovan: '#6ac9b9',
      mthn: '#FEC726'
    }[networkBranch] || '#FEC726'
  )
}

export const SocialIcons = ({ extraClass = '', networkBranch = '' }) => {
  const backgroundColor = '#ffffff'
  const iconColor = '#E8151C'
  return (
    <div className={`ft-SocialIcons ${extraClass}`}>
      <IconTwitter backgroundColor={backgroundColor} color={iconColor} text="MTHN Twitter" url="https://twitter.com/" />
      <IconTelegram backgroundColor={backgroundColor} color={iconColor} text="MTHN Telegram" url="https://t.me/" />
      <IconPOA
        backgroundColor={backgroundColor}
        color={iconColor}
        text="MTHN Network"
        url="https://explorer.evokescan.org/"
      />
    </div>
  )
}
